@import url(//netdna.bootstrapcdn.com/font-awesome/3.2.1/css/font-awesome.css);
@import url(//netdna.bootstrapcdn.com/font-awesome/3.2.1/css/font-awesome.css);
.userDropdown {
  display: none;
}
@media (max-width: 640px){
  .hide{
    display: none;
  }
  
}
@media (min-width: 640px){
  .hide_m{
    display: none;
  }
}
.nav{
  width: 100%;
  height: 7vh;
  background-color: whitesmoke;
  box-shadow: 4px 8px 10px rgb(192, 192, 192);
}

.nav_blank{
  display: inline;
  position: relative;
  width: 100vw;
  height: 10vh;
}

.userDropdownBtn:hover .userDropdown {
  display: block;
  display: flex;
  flex-direction: column;
  transition: all 1s;
}
.search_shadow{
  box-shadow: .1px .1px 2px gray;
}

.f1{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding-bottom: 4vh;
}
.f3_title{
  font-weight: 900;
}
.f3{
  color: #87898A;
  padding-top: 2vh;
  text-align: left ;
}
.f3_1{
  display: flex;
  flex-direction: row;
  padding-top: 1vh;
  justify-content: space-evenly;
  align-items: center;
}
.f4{
  margin: 1vh ;
}
.returnImg{
  height:8vh;
  padding-right: 1vh;
}
.f3_2{
  display: flex;
  flex-direction: row;
  padding-top: 1vh;
  justify-content: flex-start;
  align-items: center;
  border-radius: 2vh;
  margin-bottom: 2vh;

}

.footerBottom{
  padding-top: 4vh;
}
.wrapper{
    position: relative;
    min-height: 80vh;
    width: 100%;
    margin-bottom: 50px;
}
.push{
    height: 50px;
    width: 100%;
}
@media (max-width: 640px){
  .slider {
    width: 100%;
    background: #d3d3d3;
    outline: none;
    transition: opacity 2s;
  }
  .logo_m{
    /* margin-bottom: 20px; */
    width: 20vw; 
    margin: 2.5%;
    margin-left: 1.5vw;
  }
  .search_m{
    margin: auto 2vw;
  }
}
@media (min-width: 640px){
  .slider {
    /* -webkit-appearance: none; */
    width: 100%;
    margin-top: 6.6rem;
    height: 53vh;
    border-radius: 5px;
    background: #d3d3d3;
    outline: none;
    transition: opacity 2s;
  } 
  .logo_m{
    /* padding: 1vh; */
    align-items: center;
    width: 10vw;
    height: 6vh; 
    margin:  auto;
  }
  
}


.social{
  position: relative;
  display: flex;
  flex-direction: row; 
  justify-content: space-around;
  background-color: rgba(0, 0, 0, 0);
  height: 13%;
  width: 100%;
  color: black;
  z-index: 10;
}
.social_icon>img{
  width: 3.5vw;
  margin: 5px;
  
}
.social_icon{
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5px;
}
.social_icon>img:hover{
  width: 4.5vw;
  fill: #FFA800;
  box-shadow: 1px 1px 15px gray;
  border-radius: 21.2px ;
}

.sbr{
  position: fixed;
  margin: auto;
  width: 67%;
  display: flex;
  left: 16.5%;
  top: 8%;
  margin: auto;
  flex-wrap: nowrap;

}
.sbr>div{
  width: 100%;
}

.bgimg {
  background-image: url(/static/media/download.4592f89d.png);
  height: 100vh;

  background-position: center;
  background-size: cover;
  /* position: relative; */
  color: white;
  font-family: "Courier New", Courier, monospace;
  font-size: 25px;
}

.topleft {
  position: absolute;
  top: 0;
  left: 35%;
}

.bottom1 {
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.middle1 {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.blob_m{
  /* background-color: rgba(255, 255, 255, 0.603); */
  /* border-radius: 59% 38% 69% 58% / 56% 35% 88% 56%;  */
}
.cat_image{
  border-color: rgba(0, 0, 0, 0.278);
  border-width: .1px;
  display: block;
  height: 6rem;
  width: 6rem;
  background-color: rgba(255, 255, 255, 0.434);
}
.organic{
  margin-top: 67vh;
  margin-left: 10vh;
  color: azure;
  font-size: 11vh;
  letter-spacing: 3.5vh; /* IE 9*/ 
  transform: rotate(-90deg);
}
.dotsContainer{
  display: flex;
  justify-content: center;
  grid-gap: 30px;
  gap: 30px;
  width: 50%;
  margin-top: 45vw;
  /* margin-left: 20vw; */
  z-index: 5;
}
.dotBtns{
  width: 1vw;
  height: 1vw;
  border: 1px solid white;
  border-radius: 50%;

}
.selectstore{
  border: 10px;
  margin: 2vh;
  padding: 1vh;
  border-radius: 10px;
  background-color: rgba(183, 183, 183, 0.191);
  
}
@media (max-width: 640px){
  .cat_bg{
    background-color: rgb(94, 94, 94);
    background-position: center;
    background-repeat: no-repeat;
    background-size:cover;
    padding: 8px;
    font-size: 8px;
    margin-left: 1vw;
    margin-right: 1vw;
    height: 6vh;
    width: 8vh;
    border-radius: 2vh;
  }
  .catBar{
    margin-top: .5vh;
    background-color: #b3b3b31a;
    border-radius: 30px;
    width: 100%;
    height: 5.5rem;
    z-index: 5;
    color: black;
  }
  .catBar_main{
    width: 100%;
    overflow-x: auto;
    padding: .5vh;
    position:relative;
    z-index: 5;
    left: 0;
    color: black;
    height: 5%;
  }
  .blob{
    height: 5rem;
    width: 5rem;
    background-color: rgba(255, 255, 255, 0.603);
    border-radius: 50%;
  }
  .carousel {
    display: flex;
    grid-gap: 16px;
    gap: 16px; /* Space between cards */
    padding: 16px;
    scroll-behavior: smooth;
  }
  
  .bstslr{
    background-color: rgba(165, 165, 165, 0.432);
    /* background-image: linear-gradient( to right, rgba(165, 165, 165, 0.432),white, white,white, white); */
    font-size: small;
    font-weight: 400;
    border-radius: 8px;
    margin-left: 2%;
    text-align: center;
    margin-right: 2%;
}
}
@media (min-width: 640px){
  .cat_bg{
    background-color: rgb(94, 94, 94);
    background-position: center;
    background-repeat: no-repeat;
    background-size:cover;
    margin-left: 3vw;
    margin-right: 3vw;
    height: 12vh;
    width: 12vh;
    border-radius: 2vh;
  }
  .catBar{
    background-color: #b3b3b31a;
    border-radius: 30px;
    width: 100%;
    height: 8rem;
    z-index: 5;
    color: black;
  }
  .catBar_main{
    width: 100%;
    padding: 1vh;
    position:relative;
    z-index: 5;
    left: 0;
    color: black;
  }
  .blob{
    height: 7rem;
    width: 7rem;
    background-color: rgba(255, 255, 255, 0.603);
    border-radius: 50%;
  }
  .bstslr{
    background-color: rgba(165, 165, 165, 0.432);
    /* background-image: linear-gradient( to right, rgba(165, 165, 165, 0.432),white, white,white, white); */
    font-size: larger;
    font-weight: 600;
    border-radius: 40px;
    margin-left: 3%;
    text-align: center;
    margin-right: 3%;
    padding: 1vh ;
}
}
.cat_bg:hover .stores-dropdown{
  display: block;
}

.bg-white1{
  --bg-opacity: 1;
  background-color: #fff;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
  transition: 1s all ease
}
.bg-black{
  --bg-opacity: 1;
  background-color: #000000;
  /* background-color: rgba(255, 255, 255, var(--bg-opacity)); */
  transition: 1s all ease
}
@media (max-width: 640px){
  .t0{
    overflow: hidden;
    /* width: 100vw; */
  }
  .testimonials{
    display: flex;
    flex-direction: row;
    /* justify-content: space-around; */
    align-items: center;
    position: relative;
    height: 50vh;
    width: 92vw;
    /* background-color: rgb(230, 230, 230); */
    padding: 1rem;
  }
  .test1{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    position: relative;
    left: -10vw; 
    background-color: rgb(230, 230, 230);
    border-radius: 2rem;
  } 
  .test3{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    position: relative;
    right: -10vw; 
    background-color: rgb(230, 230, 230);
    border-radius: 2rem;

  }
  .test2{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    height:100%;
    background-color: rgb(230, 230, 230);
    border-radius: 2rem;
  }
}
@media (min-width: 720px){
  .testimonials{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    position: relative;
    height: 50vh;
    width: 100%;
    background-color: rgb(230, 230, 230);
    padding: 1rem;
  }
  .test1, .test3{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    position: relative;
    margin: 1rem;
    height: 80%;
    width: 25%;
    background-color: rgba(255, 255, 255, 0.623);
    border-radius: 2rem;
  }
  .test2{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    /* margin: 1rem; */
    height:100%;
    width: 30%;
    background-color: #ffffff;
    border-radius: 2rem;
  }
}



.testImg{
  height: 15vh;
  border-radius: 10rem;
}
.testImgC{
  position: absolute;
  height: 5%;
  top: -10%;
  border-radius: 2rem;
}
.testName{
  margin-top: 15vh;
  font-size: 1.5rem;
  font-weight: 600;
}
.testContent{
  margin: 2.5rem;
  text-align: center;
}
.testImg1{
  height: 12vh;
  border-radius: 10rem;
}
.testImgC1{
  position: absolute;
  height: 5%;
  top: -15%;
  border-radius: 2rem;
}
.testName1{
  margin-top: 10vh;
  font-size: 1.8vw;
  font-weight: 600;
}
.testContent1{
  margin: 2rem;
  font-size: 1vw;
  text-align: center;
}

.blogTitle{
  top: 8%;
  left: 6%;
  font-size: 3rem;
  font-weight: 700;

}
.shop_bottom{
  height:3vh;

}
/* .shop_bottom:hover{

  transition: 0.1s;
  height:6vh;
  
} */
/* .store_banner:hover{
  .shop_bottom{
    transition: 2s;
    height:6vh;
  }
} */
 
.no-category {
  text-align: center;
  padding: 10px;
  border-bottom: 1px solid #eee;
} 

/* AffiliateLinkGenerator.css */

.container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

h1 {
  font-size: 2em;
  margin-bottom: 20px;
}

input[type="text"] {
  width: 100%;
  padding: 10px;
  font-size: 1em;
  /* margin-bottom: 20px; */
  /* border: 1px solid #ccc; */
  border-radius: 4px;
}

input[type="text"]:-ms-input-placeholder {
  color: #888;
  font-style: italic;
}

input[type="text"]::placeholder {
  color: #888;
  font-style: italic;
}

button {
  padding: 10px 20px;
  font-size: 1em;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

.affiliate-link {
  margin-top: 20px;
}

.affiliate-link p {
  font-size: 1.2em;
  margin-bottom: 10px;
}

.affiliate-link a {
  color: #007bff;
  text-decoration: none;
}

.affiliate-link a:hover {
  text-decoration: underline;
}

.track-button {
  margin-top: 20px;
  background-color: #28a745;
}

.track-button:hover {
  background-color: #218838;
}

/* AffiliateDashboard.css */
.dashboard-container {
  width: 80%;
  margin: 20px auto 0 auto; /* Adjust margin to add space at the top */
  text-align: center;
}

.affiliate-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.affiliate-table th, .affiliate-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.affiliate-table th {
  background-color: #f4f4f4;
  color: #333;
}

.affiliate-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.affiliate-table tr:hover {
  background-color: #f1f1f1;
}

.track-button {
  margin: 20px;
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  cursor: pointer;
}

.track-button:hover {
  background-color: #45a049;
}

fieldset,
label {
  margin: 0;
  padding: 0;
}

/****** Style Star Rating Widget *****/

.rating {
  border: none;
  float: left;
}

.rating > input {
  display: none;
}

.rating > label:before {
  margin: 5px;
  font-size: 2.25em;
  font-family: FontAwesome;
  display: inline-block;
  content: "\f005";
}

/* Half Rating System */

/*.rating > .half:before { 
  content: "\f089";
  position: absolute;
}
*/

/* 
<input type="radio" id="star4half" name="rating" defaultValue={4.5} /><label className="half" htmlFor="star4half" title="Pretty good - 4.5 stars" />

<input type="radio" id="star3half" name="rating" defaultValue={3.5} /><label className="half" htmlFor="star3half" title="Meh - 3.5 stars" />

<input type="radio" id="star2half" name="rating" defaultValue={3.0} /><label className="half" htmlFor="star2half" title="Kinda bad - 2.5 stars" />

<input type="radio" id="star1half" name="rating" defaultValue={2.5} /><label className="half" htmlFor="star1half" title="Meh - 1.5 stars" />

<input type="radio" id="starhalf" name="rating" defaultValue={0.5} /><label className="half" htmlFor="starhalf" title="Sucks big time - 0.5 stars" />
*/

.rating > label {
  color: #ddd;
  float: right;
}

/***** CSS Magic to Highlight Stars on Hover *****/

.rating>input:checked~label,
/* show gold star when clicked */
.rating:not(:checked)>label:hover,
/* hover current star */
.rating:not(:checked)>label:hover~label {
  color: #b7791f;
}

/* hover previous stars in list */

.rating>input:checked+label:hover,
/* hover current star when changing rating */
.rating>input:checked~label:hover,
.rating>label:hover~input:checked~label,
/* lighten current selection */
.rating>input:checked~label:hover~label {
  color: #b7791f;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  overflow: auto;
}

/* Modal styles */
.modal {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 50vw;
  /* Fixed width to cover 50% of the viewport width */
  height: 50vh;
  /* Fixed height to cover 50% of the viewport height */
  max-width: 500px;
  /* Maximum width for larger screens */
  max-height: 80vh;
  /* Maximum height to ensure it's not too tall */
  overflow: hidden;
  /* Hide default scrollbar */
  position: relative;
  box-sizing: border-box;
  /* Include padding and border in element's total width and height */
}

/* Modal content with scrollbar */
.modal-content {
  overflow: auto;
  /* Allow scrolling if content overflows */
  height: calc(100% - 50px);
  /* Subtract height of the close button */
  padding-right: 15px;
  /* Space for scrollbar */
  box-sizing: content-box;
  /* Exclude padding from width calculations */
  margin-top: 40px;
  /* Space below the close button */
}

/* Close button styling */
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #f44336;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 4px;
  /* Rounded corners for the button */
}

/* Dropdown styles */
.dropdown {
  position: relative;
  width: 100%;
  /* Make dropdown take full width of modal */
  margin-top: 50px;
  /* Space below the close button */
}

.dropdown-toggle {
  background-color: #4CAF50;
  /* Green background */
  color: white;
  /* White text */
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 8px;
  /* Match the modal's border radius */
  width: 100%;
  /* Full width of the modal */
  box-sizing: border-box;
  /* Include padding in width calculations */
}

.dropdown-menu {
  display: block;
  position: absolute;
  background-color: #f9f9f9;
  /* Light grey background */
  width: 100%;
  /* Full width of the dropdown toggle */
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 8px;
  /* Match the modal's border radius */
  top: 100%;
  /* Position it below the toggle button */
  left: 0;
  margin-top: 8px;
  /* Add some space between the toggle and menu */
  box-sizing: border-box;
  /* Include padding and border in element's total width */
}

.coupon-item {
  padding: 12px 16px;
  border-bottom: 1px solid #ddd;
}

.coupon-item:last-child {
  border-bottom: none;
}

.coupon-name {
  font-weight: bold;
}

.coupon-description {
  margin-bottom: 8px;
}

/* Dropdown container */
.dropdown {
  position: relative;
  display: inline-block;
  width: 100%;
  /* Adjust width as needed */
}

/* Dropdown toggle button */
.dropdown-toggle {
  background-color: #4CAF50;
  /* Green background */
  color: white;
  /* White text */
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 8px;
  /* Rounded corners */
  width: 100%;
  /* Full width of the dropdown container */
}

/* Dropdown menu */
.dropdown-menu {
  display: block;
  position: absolute;
  background-color: #f9f9f9;
  /* Light grey background */
  width: 100%;
  /* Full width of the dropdown toggle */
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 8px;
  /* Rounded corners */
  top: 100%;
  /* Position it below the toggle button */
  left: 0;
  margin-top: 8px;
  /* Space between the toggle and menu */
  max-height: 300px;
  /* Limit the height of the dropdown */
  overflow-y: auto;
  /* Scroll if content overflows */
}

/* Dropdown item */
.coupon-item {
  padding: 12px;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
}

.coupon-item:last-child {
  border-bottom: none;
}

.coupon-name {
  font-weight: bold;
}

.coupon-description {
  margin-bottom: 8px;
}

.MuiMenuItem-root.Mui-disabled {
  background-color: #f0f0f0;
  /* Light grey for disabled items */
  cursor: not-allowed;
}
.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}

.modal-content {
  position: relative; /* Needed to position the close button inside the modal */
  width: 400px;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.userDropdown {
  display: none;
}
@media (min-width: 640px){
  .top-line{
    background-color: #A56CA6; 
    align-items: center;
    height: 4.2vh;
    justify-content: space-around;
    padding: auto;
    color: #000000; 
    font-size: 0.7em;
    overflow: hidden;
  }
  .wordStream{
    position: relative;
    animation: move-words 10s linear infinite;
    margin: 0;
  }
}

@media (max-width: 640px){
  .top-line{
    background-color: #A56CA6; 
    align-items: center;
    overflow: hidden;
    height: 4.2vh;
    padding: 2%;
    justify-content: space-around;
    padding: auto;
    color: #000000; 
    font-size: 0.7em
  }
  .wordStream{
    width: 300vw;
    overflow: hidden;
    position: relative;
    animation: move-words 10s linear infinite;
    margin: 0;
  }
}
.userDropdownBtn:hover .userDropdown {
  display: block;
  display: flex;
  flex-direction: column;
  transition: all 1s;
}
.search_shadow{
  box-shadow: .1px .1px 2px gray;
}

.f1{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding-bottom: 4vh;
}
.f2{
  color: white;
  text-align: left;
  flex-direction: column;

}
.f3_title{
  font-weight: 900;
}
.f3{
  color: #87898A;
  padding-top: 2vh;
  text-align: left ;
}
.f3_1{
  display: flex;
  flex-direction: row;
  padding-top: 1vh;
  justify-content: space-evenly;
  align-items: center;
}
.f4{
  margin: 1vh ;
}
.returnImg{
  height:8vh;
  padding-right: 1vh;
}
.f3_2{
  display: flex;
  flex-direction: row;
  padding-top: 1vh;
  justify-content: center;
  align-items: center;
  border-radius: 2vh;
  margin-bottom: 2vh;
  margin: auto;

}

.footerBottom{
  padding-top: 4vh;
}



@keyframes move-words {
  0% {
    left: 65%;
  }
  100% {
    left: -65%;
  }
}

.store_wrapper{
    position: relative;
    min-height: 80vh;
    width: 100%;
    margin-bottom: 50px;
    background-color: white;
}
.push{
    height: 50px;
    width: 100%;
}
fieldset,
label {
  margin: 0;
  padding: 0;
}

/****** Style Star Rating Widget *****/

.rating {
  border: none;
  float: left;
}

.rating > input {
  display: none;
}


.rating > label:before {
  margin: 5px;
  font-size: 2.25em;
  font-family: FontAwesome;
  display: inline-block;
  content: "\f005";
}

/* Half Rating System */

/*.rating > .half:before { 
  content: "\f089";
  position: absolute;
}
*/

/* 
<input type="radio" id="star4half" name="rating" defaultValue={4.5} /><label className="half" htmlFor="star4half" title="Pretty good - 4.5 stars" />

<input type="radio" id="star3half" name="rating" defaultValue={3.5} /><label className="half" htmlFor="star3half" title="Meh - 3.5 stars" />

<input type="radio" id="star2half" name="rating" defaultValue={3.0} /><label className="half" htmlFor="star2half" title="Kinda bad - 2.5 stars" />

<input type="radio" id="star1half" name="rating" defaultValue={2.5} /><label className="half" htmlFor="star1half" title="Meh - 1.5 stars" />

<input type="radio" id="starhalf" name="rating" defaultValue={0.5} /><label className="half" htmlFor="starhalf" title="Sucks big time - 0.5 stars" />
*/

.rating > label {
  color: #ddd;
  float: right;
}

/***** CSS Magic to Highlight Stars on Hover *****/

.rating>input:checked~label,
/* show gold star when clicked */
.rating:not(:checked)>label:hover,
/* hover current star */
.rating:not(:checked)>label:hover~label {
  color: #b7791f;
}

/* hover previous stars in list */

.rating>input:checked+label:hover,
/* hover current star when changing rating */
.rating>input:checked~label:hover,
.rating>label:hover~input:checked~label,
/* lighten current selection */
.rating>input:checked~label:hover~label {
  color: #b7791f;
}

.slider_s {
  -webkit-appearance: none;
  border-radius: 5px;
  background: #d3d3d3;
  outline: none;
  transition: opacity 2s;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 18px;
  height: 18px;
  border-radius: 75%;
  background: #b7791f;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 18px;
  height: 18px;
  border-radius: 75%;
  background: #b7791f;
  cursor: pointer;
}
.logo{
  display: flex;
  padding: 8%;
  padding-top: 8%;
  padding-bottom: 8%;
  align-items: center;
  width: 100%; 
  margin: auto;
  background-color: rgba(255, 255, 255, 0.253); 
  margin-bottom: 1vh;
  margin-left: .5vw;
  border-radius: 15px;
}

.sbr{
  position: fixed;
  margin: auto;
  width: 67%;
  display: flex;
  left: 16.5%;
  top: 8%;
  margin: auto;
  flex-wrap: nowrap;

}
.sbr>div{
  width: 100%;
}
.blob_img{
  border-radius: 50%;
}

.bg-white1{
  --bg-opacity: 1;
  background-color: #fff;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
  transition: 1s all ease
}


.blogTitle{
  top: 8%;
  left: 6%;
  font-size: 3rem;
  font-weight: 700;

}
.stores-dropdown{
  display: block;
}
*, *::after, *::before {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }
  
  .trackingbody {
    color: #2c3e50;
    font-family: 'Montserrat', sans-serif;
    width: 40rem;
    font-weight: 300;
    min-height: 100vh;
    position: relative;
    display: block;
    margin: 2rem auto;
  }
  
  .root {
    padding: 1rem;
    border-radius: 5px;
    box-shadow: 0 2rem 6rem rgba(0, 0, 0, 0.3);
  }
  
  .order-track {
    margin-top: 2rem;
    padding: 0 1rem;
    border-top: 1px dashed #2c3e50;
    padding-top: 2.5rem;
    display: flex;
    flex-direction: column;
  }
  .order-track-step {
    display: flex;
    height: 7rem;
  }
  .order-track-step:last-child {
    overflow: hidden;
    height: 4rem;
  }
  .order-track-step:last-child .order-track-status span:last-of-type {
    display: none;
  }
  .order-track-status {
    margin-right: 1.5rem;
    position: relative;
  }
  .order-track-status-dot {
    display: block;
    width: 2.2rem;
    height: 2.2rem;
    border-radius: 50%;
    background: #f05a00;
  }
  .order-track-status-line {
    display: block;
    margin: 0 auto;
    width: 2px;
    height: 7rem;
    background: #f05a00;
  }
  .order-track-text-stat {
    font-size: 1.3rem;
    font-weight: 500;
    margin-bottom: 3px;
  }
  .order-track-text-sub {
    font-size: 1rem;
    font-weight: 300;
  }
  
  .order-track {
    transition: all .3s height 0.3s;
    transform-origin: top center;
  }
/* AffiliateLinkGenerator.css */

.container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

h1 {
  font-size: 2em;
  margin-bottom: 20px;
}

button {
  padding: 10px 20px;
  font-size: 1em;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

.affiliate-link {
  margin-top: 20px;
}

.affiliate-link p {
  font-size: 1.2em;
  margin-bottom: 10px;
}

.affiliate-link a {
  color: #007bff;
  text-decoration: none;
}

.affiliate-link a:hover {
  text-decoration: underline;
}

.track-button {
  margin-top: 20px;
  background-color: #28a745;
}

.track-button:hover {
  background-color: #218838;
}

/* AffiliateDashboard.css */
.dashboard-container {
  width: 80%;
  margin: 20px auto 0 auto; /* Adjust margin to add space at the top */
  text-align: center;
}

.affiliate-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  margin-bottom: 20px;
}

.affiliate-table th, .affiliate-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.affiliate-table th {
  background-color: #f4f4f4;
  color: #333;
}

.affiliate-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.affiliate-table tr:hover {
  background-color: #f1f1f1;
}

.track-button {
  margin: 20px;
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  cursor: pointer;
}

.track-button:hover {
  background-color: #45a049;
}

.total-commission {
  margin-top: 20px; /* Adds space above the total commission text */
  font-weight: bold;
  color: #333; /* Optional styling */
}
