/* AffiliateLinkGenerator.css */

.container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

h1 {
  font-size: 2em;
  margin-bottom: 20px;
}

button {
  padding: 10px 20px;
  font-size: 1em;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

.affiliate-link {
  margin-top: 20px;
}

.affiliate-link p {
  font-size: 1.2em;
  margin-bottom: 10px;
}

.affiliate-link a {
  color: #007bff;
  text-decoration: none;
}

.affiliate-link a:hover {
  text-decoration: underline;
}

.track-button {
  margin-top: 20px;
  background-color: #28a745;
}

.track-button:hover {
  background-color: #218838;
}

/* AffiliateDashboard.css */
.dashboard-container {
  width: 80%;
  margin: 20px auto 0 auto; /* Adjust margin to add space at the top */
  text-align: center;
}

.affiliate-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  margin-bottom: 20px;
}

.affiliate-table th, .affiliate-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.affiliate-table th {
  background-color: #f4f4f4;
  color: #333;
}

.affiliate-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.affiliate-table tr:hover {
  background-color: #f1f1f1;
}

.track-button {
  margin: 20px;
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  cursor: pointer;
}

.track-button:hover {
  background-color: #45a049;
}

.total-commission {
  margin-top: 20px; /* Adds space above the total commission text */
  font-weight: bold;
  color: #333; /* Optional styling */
}