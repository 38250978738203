.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  overflow: auto;
}

/* Modal styles */
.modal {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 50vw;
  /* Fixed width to cover 50% of the viewport width */
  height: 50vh;
  /* Fixed height to cover 50% of the viewport height */
  max-width: 500px;
  /* Maximum width for larger screens */
  max-height: 80vh;
  /* Maximum height to ensure it's not too tall */
  overflow: hidden;
  /* Hide default scrollbar */
  position: relative;
  box-sizing: border-box;
  /* Include padding and border in element's total width and height */
}

/* Modal content with scrollbar */
.modal-content {
  overflow: auto;
  /* Allow scrolling if content overflows */
  height: calc(100% - 50px);
  /* Subtract height of the close button */
  padding-right: 15px;
  /* Space for scrollbar */
  box-sizing: content-box;
  /* Exclude padding from width calculations */
  margin-top: 40px;
  /* Space below the close button */
}

/* Close button styling */
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #f44336;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 4px;
  /* Rounded corners for the button */
}

/* Dropdown styles */
.dropdown {
  position: relative;
  width: 100%;
  /* Make dropdown take full width of modal */
  margin-top: 50px;
  /* Space below the close button */
}

.dropdown-toggle {
  background-color: #4CAF50;
  /* Green background */
  color: white;
  /* White text */
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 8px;
  /* Match the modal's border radius */
  width: 100%;
  /* Full width of the modal */
  box-sizing: border-box;
  /* Include padding in width calculations */
}

.dropdown-menu {
  display: block;
  position: absolute;
  background-color: #f9f9f9;
  /* Light grey background */
  width: 100%;
  /* Full width of the dropdown toggle */
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 8px;
  /* Match the modal's border radius */
  top: 100%;
  /* Position it below the toggle button */
  left: 0;
  margin-top: 8px;
  /* Add some space between the toggle and menu */
  box-sizing: border-box;
  /* Include padding and border in element's total width */
}

.coupon-item {
  padding: 12px 16px;
  border-bottom: 1px solid #ddd;
}

.coupon-item:last-child {
  border-bottom: none;
}

.coupon-name {
  font-weight: bold;
}

.coupon-description {
  margin-bottom: 8px;
}

/* Dropdown container */
.dropdown {
  position: relative;
  display: inline-block;
  width: 100%;
  /* Adjust width as needed */
}

/* Dropdown toggle button */
.dropdown-toggle {
  background-color: #4CAF50;
  /* Green background */
  color: white;
  /* White text */
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 8px;
  /* Rounded corners */
  width: 100%;
  /* Full width of the dropdown container */
}

/* Dropdown menu */
.dropdown-menu {
  display: block;
  position: absolute;
  background-color: #f9f9f9;
  /* Light grey background */
  width: 100%;
  /* Full width of the dropdown toggle */
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 8px;
  /* Rounded corners */
  top: 100%;
  /* Position it below the toggle button */
  left: 0;
  margin-top: 8px;
  /* Space between the toggle and menu */
  max-height: 300px;
  /* Limit the height of the dropdown */
  overflow-y: auto;
  /* Scroll if content overflows */
}

/* Dropdown item */
.coupon-item {
  padding: 12px;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
}

.coupon-item:last-child {
  border-bottom: none;
}

.coupon-name {
  font-weight: bold;
}

.coupon-description {
  margin-bottom: 8px;
}

.MuiMenuItem-root.Mui-disabled {
  background-color: #f0f0f0;
  /* Light grey for disabled items */
  cursor: not-allowed;
}